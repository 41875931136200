<template>
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.25 10.75L4.02463 9.68283C4.2021 9.61457 4.29084 9.58045 4.37386 9.53588C4.4476 9.4963 4.5179 9.45061 4.58402 9.3993C4.65846 9.34154 4.72569 9.27431 4.86014 9.13986L10.5 3.5C11.0523 2.94771 11.0523 2.05228 10.5 1.5C9.94773 0.947714 9.0523 0.947714 8.50001 1.5L2.86014 7.13986C2.72569 7.27431 2.65846 7.34154 2.6007 7.41598C2.54939 7.4821 2.5037 7.5524 2.46412 7.62614C2.41955 7.70916 2.38542 7.7979 2.31717 7.97537L1.25 10.75ZM1.25 10.75L2.27906 8.07448C2.3527 7.88302 2.38952 7.78729 2.45267 7.74344C2.50786 7.70512 2.57615 7.69063 2.64215 7.70323C2.71767 7.71765 2.79019 7.79018 2.93524 7.93523L4.06479 9.06477C4.20984 9.20982 4.28236 9.28235 4.29678 9.35787C4.30939 9.42386 4.29489 9.49215 4.25657 9.54734C4.21272 9.6105 4.11699 9.64732 3.92553 9.72095L1.25 10.75Z"
      stroke="white"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
